<template>
  <div style="width: 100%;height: 100%;">
    <div class="content">
      <div class="contentR">
        <div class="prompt">
          <img src="../../assets/images/LTicon.png" alt="">
          <div class="promptText">碳排分析</div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="日期：">
                  <el-date-picker :append-to-body="false" type="year" placeholder="选择日期" 
                    class="custom-input" v-model="formInline.updateTime" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="previousYear(0)" icon="el-icon-arrow-left"
                    class="custom-button">上一年</el-button>
                  <el-button type="primary" @click="previousYear(1)" icon="el-icon-arrow-right"
                    class="custom-button">下一年</el-button>
                  <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
                  <!-- <el-button type="primary" @click="downLoad" icon="el-icon-download" class="custom-button">导出</el-button> -->
                </el-form-item>
              </el-form>
            </div>
            <div class="newView1">
              <!-- <el-button type="primary" @click="openViewDialog(0)" icon="el-icon-plus"
                class="custom-button custom-button-yellow">图表</el-button>
              <el-button type="primary" @click="openViewDialog(1)" icon="el-icon-s-data"
                class="custom-button">数据</el-button> -->

            </div>

          </div>


        </div>

        <div class="searchContent1">
          <div class="titleE">
            <!-- <img src="../../assets/images/titleT.png" alt="">
            <div class="promptText">发电及收益情况统计</div> -->
          </div>
          <div class="titleEcharts">同比分析情况统计</div>

        </div>
        <div class="searchContent1">
          <div id="myEcharts" class="mycharts">
          </div>
        </div>
        <div class="titleEcharts">环比分析情况统计</div>
        <div class="searchContent">
          <div class="search">
            <div class="newView">
              <div class="day" v-for="(item, index) in dayList" :key="index" :class="day == index ? 'day1' : ''"
                @click="dayFn(index)">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="searchContent">
          <div class="search">
            <div class="formList">
              <el-form :inline="true" :model="formMonth" class="demo-form-inline">
                <el-form-item label="日期：">
                  <el-date-picker v-show="timeType == 'date'" type="date" :append-to-body="false" placeholder="选择日期"
                    value-format="yyyy-MM-dd" class="custom-input" v-model="formMonth.updateTime"
                    style="width: 100%;"></el-date-picker>
                  <el-date-picker v-show="timeType == 'month'" type="month" :append-to-body="false" placeholder="选择日期"
                    value-format="yyyy-MM-dd" class="custom-input" v-model="formMonth.updateTime"
                    style="width: 100%;"></el-date-picker>
                  <el-date-picker v-show="timeType == 'year'" type="year" :append-to-body="false" placeholder="选择日期"
                    value-format="yyyy-MM-dd" class="custom-input" v-model="formMonth.updateTime"
                    style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmitMonth" icon="el-icon-search"
                    class="custom-button">查询</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="newView1">

            </div>

          </div>


        </div>


        <div class="table">
          <el-table border :data="tableData" style="width: 100%" class="custom-table">
            <el-table-column align="center" label="序号" type="index" width="200">
            </el-table-column>
            <el-table-column align="center" prop="loopName" label="回路名称" :fluid="true">
            </el-table-column>
            <el-table-column align="center" prop="nowTanPait" :label="`当${day == 0 ? '日' : '月'}碳排/tCO2e`" width="200">
            </el-table-column>
            <el-table-column align="center" prop="lastTanPait" :label="`上${day == 0 ? '日' : '月'}碳排/tCO2e`" width="200">
            </el-table-column>
            <el-table-column align="center" prop="powerRatio" label="增加值" width="200">
            </el-table-column>
            <el-table-column align="center" prop="emissions2" label="环比(%)" width="200">
              <template slot-scope="scope">
                <img class="tableIcon" v-if="scope.row.powerRatio <= 0" src="../../assets/images/analysis/decline.png"
                  alt="">
                <img class="tableIcon" v-else src="../../assets/images/analysis/rise.png" alt="">
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="emissions2" label="操作" width="200">
              <template slot-scope="scope">
                <el-button type="primary" @click="openTableDialog" class="custom-table-button">图表</el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <!-- <div class="pagination">
          <div class="btn mouseCursor" @click="startHome">首页</div>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next, sizes"
            :prev-text="prevButtonText" :next-text="nextButtonText" prev-button-class="custom-prev-button"
            next-button-class="custom-next-button" :total="total">
          </el-pagination>
          <div class="btn endBtn" @click="endHome">尾页</div>
          <div class="paginationEnd">
            <div>跳</div>至
            <el-input size="mini" @change="paginationEnd" class="paginationEndIpt"
              v-model.number="paginationEndIpt"></el-input>
            <div>页</div>
            <div class="btn confirm mouseCursor" @click="jumpPage">确定</div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 导出 -->
    <el-dialog title="下载" :visible.sync="downLoadDialogVisible" width="20%">
      <div class="deleteCenter">
        确定下载该文件?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialogBtn" @click="downLoadDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="downLoadDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'

import * as echarts from "echarts";
export default {
  name: 'Administration',
  data() {
    return {
      downLoadDialogVisible: false,
      formInline: {
        updateTime: '',//创建时间
      },
      formMonth: {
        updateTime: '',//创建时间
      },

      dayList: ['按日', '按月',],
      day: 0,


      textarea: '',
      tableData: [
      ],

      checkedNodes: [],
      selectAll: false,
      selectAllText: '全选',
      treeTitle: [],
      treeText: '',
      selectContent: '',
      inputValue: '',
      defaultProps: {
        children: 'children',
        id: 'id',
        label: 'label'
      },
      // prevButtonText: '上一页',
      // nextButtonText: '下一页',
      // paginationEndIpt: '',
      // pageSize: 10,
      // currentPage: 1,
      // total: 40,
      timeType: 'date',
      textarea: '',
      stationNumber: '',
      staTime: '',
      endTime: '',

    };
  },
  // components:{demo},
  created() {
    this.$store.commit('increment', '碳排分析')
    this.$store.commit('selectChild', '碳排分析')
    this.$store.commit("selectChildren", "");

  },

  mounted() {
    // let pageText = document.getElementsByClassName('el-pagination__jump')[0]
    // pageText.childNodes[0].nodeValue = '跳至'
    // pageText.childNodes[2].nodeValue = '页'
    this.stationNumber = sessionStorage.getItem('stationNumber')
    this.drawEnergy()
    this.getList()
    // this.drawCarbon()

  },
  computed: {

  },


  methods: {

    downLoad() {
      this.downLoadDialogVisible = true
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.paginationEnd()
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.paginationEndIpt = val
      console.log(`当前页: ${val}`);
      console.log(this.currentPage);
      this.getList()
    },
    //首页
    startHome() {
      this.currentPage = 1

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.currentPage = lastPage;
    },
    //页数输入时
    paginationEnd() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
    },
    //跳转
    jumpPage() {
      this.currentPage = this.paginationEndIpt
      // this.paginationEndIpt=''
    },
    dayFn(index) {
      // this.day = el + 1 
      this.day = index;
      this.formMonth.updateTime = ''
      if (index == 0) {
        this.timeType = 'date'
      }
      if (index == 1) {
        this.timeType = 'month'
      }
      if (index == 2) {
        this.timeType = 'year'
      }
      this.getList()
    },
    onSubmitMonth() {
      this.getList()
    },

    //列表查询
    getList() {
      let type;
      if (this.day == 0) {
        this.getDayStartAndEnd()
        type = 1
      } else if (this.day == 1) {
        this.getMonthStartAndEnd(this.formMonth.updateTime)
        type = 3
      } else {
        this.getYearStartAndEnd(this.formMonth.updateTime)
        type = 5

      }
      let obj = {
        stationNumber: this.stationNumber,
        type: type,
        staTime: this.staTime,
        endTime: this.endTime,
      }
      if (this.formMonth.updateTime && this.day == 0) {
        obj.staTime = this.formMonth.updateTime
        obj.endTime = this.formMonth.updateTime
      }
      emissionsCarbonApi.analysisTwoApi(obj).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data
        }
      })

    },

    //查询列表
    onSubmit() {
      console.log('submit!');
      this.drawEnergy()
    },
    //上一年or下一年
    previousYear(index) {
      if (index == 0) {
        if (this.formInline.updateTime) {
          let prevYear = new Date(this.formInline.updateTime)
          prevYear.setFullYear(prevYear.getFullYear() - 1)
          this.formInline.updateTime = prevYear
        }
      } else {
        if (this.formInline.updateTime) {
          let nextYear = new Date(this.formInline.updateTime)
          nextYear.setFullYear(nextYear.getFullYear() + 1)
          this.formInline.updateTime = nextYear
        }
      }
    },

    drawEnergy() {
      let obj = {
        stationNumber: this.stationNumber,
        time: this.formInline.updateTime
      }
      if (!this.formInline.updateTime) {
        var today = new Date();
        obj.time = today.getFullYear();
      } else {
        console.log(this.formInline.updateTime);
        // obj.time=this.formInline.updateTime.substring(0,4)
        var inputDate = new Date(this.formInline.updateTime);
        var year = inputDate.getFullYear();
       
        obj.time = year ;
      }
      emissionsCarbonApi.analysisOneApi(obj).then(res => {
        let now = []
        let last = []
        if (res.data) {
          res.data.forEach(item => {
            now.push(item.nowTanPait)
            last.push(item.lastTanPait)

          })
        }
        // })
        var myEcharts = echarts.init(document.getElementById("myEcharts"));
        var option = {

          grid: {
            left: '2%',
            right: '3%',
            containLabel: true
          },
          backgroundColor: '#071738',
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['本期', '同期',],
            right: '300px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          color: ['#2a65b4', ' #2fc9cb'],
          toolbox: {
            show: true,
            feature: {

              dataZoom: {
                yAxisIndex: 'none',
                iconStyle: {
                  normal: {
                    color: '#071B42',
                    borderColor: '#ffffff',
                    borderWidth: 1 
                  }
                },
              },
              dataView: {
                readOnly: false,
                iconStyle: {
                  normal: {
                    color: '#071B42',
                    borderColor: '#ffffff',
                    borderWidth: 1
                  }
                },
              },
              magicType: {
                type: ['line', 'bar'],
                iconStyle: {
                  normal: {
                    color: '#071B42',
                    borderColor: '#ffffff',
                    borderWidth: 1
                  }
                },
              },
              restore: {
                iconStyle: {
                  normal: {
                    color: '#071B42',
                    borderColor: '#ffffff',
                    borderWidth: 1
                  }
                },
              },
              saveAsImage: {
                iconStyle: {
                  normal: {
                    color: '#071B42',
                    borderColor: '#ffffff',
                    borderWidth: 1
                  }
                },
              }
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,

            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              textStyle: {
                color: '#aed6ff' // 设置 x 轴标签字体颜色
              }
            }
          },
          yAxis: {
            type: 'value',
            name: "tCO2e",
            nameTextStyle: {
              color: '#aed6ff' // 设置 Y 轴名称的字体颜色
            },
            axisLabel: {
              textStyle: {
                color: '#aed6ff' // 
              }
            },
            ameTextStyle: {
              color: '#aed6ff'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',//
                color: "#0b2159"
              },
            },
          },
          series: [
            {
              name: '本期',
              type: 'bar',
              barWidth: '20',
              data: now,



            },
            {
              name: '同期',
              type: 'bar',
              barWidth: '20',
              data: last,


            }
          ]
        };

        myEcharts.setOption(option);
      })

    },
    // getYue() {
    //   let now = new Date();
    //   let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    //   let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    //   this.staTime = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate()
    //   this.endTime = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate()
    //   console.log("这个月的第一天是：" + this.staTime);
    //   console.log("这个月的最后一天是：" + this.endTime);
    // },
    getDay() {
      var date = new Date();
      date.setDate(date.getDate());
      var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.staTime = yesterday
      this.endTime = yesterday
      console.log(this.endTime);
    },
    getZhou() {
      let now = new Date();
      let dayOfWeek = now.getDay();
      let numDay = now.getDate();
      let start = new Date(now);
      start.setDate(numDay - dayOfWeek + (dayOfWeek == 0 ? -6 : 1));
      let end = new Date(now);
      end.setDate(numDay + (7 - dayOfWeek));
      this.staTime = start.toISOString().slice(0, 10)
      this.endTime = end.toISOString().slice(0, 10)
      console.log("这周的周一日期是：" + this.staTime);
      console.log("这周的周日日期是：" + this.endTime);
    },
    getYue() {
      let now = new Date();
      let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      this.staTime = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate()
      this.endTime = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate()
      console.log("这个月的第一天是：" + this.staTime);
      console.log("这个月的最后一天是：" + this.endTime);
    },
    getDayStartAndEnd() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1；使用 padStart 补齐两位
      const day = String(today.getDate()).padStart(2, '0'); // 使用 padStart 补齐两位
      let sta;
      let end;
      if (this.formMonth.updateTime) {

        this.staTime = `${this.formMonth.updateTime}`;
        this.endTime = `${this.formMonth.updateTime}`;
      } else {
        this.staTime = `${year}-${month}-${day}`;
        this.endTime = `${year}-${month}-${day}`;
      }
      console.log('````', this.staTime, this.endTime);
    },
    getMonthStartAndEnd(dateString) {
      let date;
      if (dateString) {
        date = new Date(dateString);
      } else {
        date = new Date();
      }

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const firstDay = new Date(year, month - 1, 2);
      const lastDay = new Date(year, month, 1);

      const formatDate = (d) => {
        return d.toISOString().slice(0, 10);
      };
      this.staTime = formatDate(firstDay);
      this.endTime = formatDate(lastDay);
      console.log(this.staTime, this.endTime);

    },
    //year
    getYearStartAndEnd(dateString) {
      let date;
      if (!dateString) {
        date = new Date();
      } else {
        date = new Date(dateString);
      }
      this.staTime = new Date(date.getFullYear(), 0, 2).toISOString().slice(0, 10);

      this.endTime = new Date(date.getFullYear() + 1, 0, 1).toISOString().slice(0, 10);
      console.log(this.staTime, this.endTime);
      // return {
      //     firstDay: firstDay,
      //     lastDay: lastDay
      // };
    },



  },
};
</script>
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  display: flex;

}

.contentL {
  width: 420px;
  margin-top: 10px;
  padding: 28px 0 60px 17px;
  box-sizing: border-box;
  /* background: #071536; */

}

.contentR {
  width: 100%;
  overflow: auto;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.promptText {
  margin-left: 10px;
}

.el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
  height: 35px;
  width: 100%;
}

.el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
}

.custom-input ::placeholder {
  color: #AED6FF;
}

.custom-button {
  background: #176ad2;
}

.searchContent {
  padding: 10px 25px 10px 26px;
  background-color: #071B42;
  margin: 0 20px;
}

.searchContent1 {
  padding: 10px 25px 10px 26px;
  background-color: #071738;
  margin: 0 20px;
}

.search {
  display: flex;
  /* padding: 0 10px; */
  justify-content: space-between;
  align-items: center;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  margin-bottom: 20px;
  display: flex;
}

.newCenterText {
  width: 80px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
}

.custom-input-textarea ::placeholder {
  color: #667597;
}

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

/* tree样式修改 */
::v-deep .el-tree {
  background: #03183F !important;
  color: #aed6ff;
  padding-bottom: 10px;
}


::v-deep .el-tree-node {
  background: #03183F !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183F !important;
  color: #FFC74A;
}

::v-deep .is-checked {
  background: #03183F !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183F;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:focus {
  background: #03183F !important;
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183F;
}

::v-deep .el-tree-node {
  background-color: #03183F;

}

.tree {
  margin-top: 21px;
  /* background: #071734 ; */
  color: #aed6ff;

}

.treeContent {
  height: 32px;
  line-height: 32px;
  padding-left: 62px;
  font-size: 14px;
}

.treeContent:hover {
  color: #FFC74A;
}

.selectContent {
  color: #FFC74A;
}

.treeTitle {
  height: 32px;
  line-height: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.treeTitle img {
  width: 10px;
  height: 7px;
  margin-right: 5px;

}

.titleImg {
  transform: rotate(90deg);
}

.contentLtitle {
  color: #d8e3ff;
  font-size: 14px;
  margin-bottom: 20px;
}

.customSelect {
  color: #d8e3ff;
  margin-bottom: 10px;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183F !important;
}

::v-deep .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #083B7A;
  /* background-color: rgba(255, 255, 255, 0); */
}

.inputValue {
  width: 210px;
  margin-bottom: 10px;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

.newView {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.day {
  /* background-color: #041B44; */
  /* background-color: #002C6C; */
  cursor: pointer;

  border-radius: 3px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover {
  background-color: #002C6C;
  color: #ffc74a;
}

.day1 {
  background-color: #002C6C;
  color: #ffc74a;

}

.mycharts {
  width: 100%;
  height: 500px;
  margin-bottom: 15px;
}

.titleEcharts {
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
}

.formInline {
  padding-top: 15px;
}

.titleE {
  display: flex;
  align-items: center;
  padding: 10px 0 15px 0;
}

.bottomChart {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chartL,
.chartR {
  height: 100%;
  width: 31%;
  text-align: center;
  background: #071A40;
  margin: 20px 0;
}

.chartTitile {

  display: flex;
  align-items: center;
  margin: 26px 0 0 16px;
}

.chartCont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.chartimg {
  width: 190px;
  height: 190px;
  background: url(../../assets/images/circularBlue.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -26px;
}

.chartRimg {
  width: 190px;
  height: 190px;
  background: url(../../assets/images/circularY.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -26px;
}

.imgRnumber {
  color: #e0ab34;
  font-size: 24px;
  height: 28px;
  line-height: 28px;
  padding: 8px 0 4px 0;

}

.imgTitle {
  color: #e8f4ff;
  height: 26px;
  line-height: 26px;

}

.imgnumber {
  color: #2fc9cb;
  font-size: 24px;
  height: 28px;
  line-height: 28px;
  padding: 8px 0 4px 0;
}

.imgbottom {
  color: #e8f4ff;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
}

#chartEcharts {
  margin-top: 30px;
  width: 600px;
  height: 250px;
}

::v-deep.el-tree {
  width: 300px;
}

.day {
  background-color: #041B44;
  /* background-color: #002C6C; */
  cursor: pointer;
  border-radius: 3px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover {
  color: #ffc74a;
  background-color: #002C6C;
}

.day1 {
  color: #ffc74a;
  background-color: #002C6C;

}

::v-deep .el-table__body-wrapper {
  max-height: 18vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

.tableIcon {
  width: 15px;
  height: 30px;
}

/* 修改日期选择器样式 */
::v-deep .el-icon-date {
  color: #aed6ff;
}

::v-deep .el-picker-panel {
  background: #041c4c;
  color: #aed6ff;
  border-color: #041c4c;
}

::v-deep .el-date-picker__header-label {
  color: #aed6ff;
}

::v-deep .el-picker-panel__icon-btn {
  color: #aed6ff;
}

::v-deep .el-date-table th {
  color: #aed6ff;
  border-color: #aed6ff;
}

::v-deep .el-date-table .disabled div {
  background: #041c4c;
  color: #aed6ff;
}
</style>